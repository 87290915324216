import { useEffect } from 'react'

export function recordPageView(text) {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['trackPageView', text])
    }
}

export function recordProductEcommerceView(productsku, productname, categoryname) {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['setEcommerceView',
            productsku, // (Required) productSKU
            productname, // (Optional) productName
            categoryname, // (Optional) categoryName
        ]);
    }
}

export function setUserID(email) {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['setUserId', email]);
    }
}

export function resetUserID() {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['resetUserId']);
    }
}

export function addEcommerceItem(productsku, productname, price, quantity) {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['addEcommerceItem',
            productsku, // (Required) productSKU
            productname, // (Optional) productName
            undefined, // (Optional) productCategory
            price, // (Recommended) price
            quantity // (Optional, defaults to 1) quantity
        ]);
    }
}

export function removeEcommerceItem(slug) {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['removeEcommerceItem', slug]);
    }
}

export function trackEcommerceCartUpdate(qty) {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['trackEcommerceCartUpdate', qty])
    }
}

export function clearEcommerceCart() {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['clearEcommerceCart'])
    }
}

export function trackEcommerceOrder(orderCode, totalAmt, subtotalAmt, taxAmt, shippingAmt, discountAmt) {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['trackEcommerceOrder', 
            orderCode, // (Required) orderId
            totalAmt, // (Required) grandTotal (revenue)
            subtotalAmt, // (Optional) subTotal
            taxAmt, // (optional) tax
            shippingAmt, // (optional) shipping
            discountAmt // (optional) discount
        ])
    }
}

export function trackSiteSearch(searchparam, total) {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['trackSiteSearch',
            searchparam, // The search keyword
            false, // The search category (optional)
            total // Number of results returned (optional)
        ])
    }
}

export function recordCategoryEcommerceView(categoryname) {
    if (process.env.REACT_APP_DEPLOY_ENV == "live") {
        window._paq?.push(['setEcommerceView', 
            false, // Product name is not applicable for a category view
            false, // Product SKU is not applicable for a category view.
            categoryname // (Optional) categoryName
        ])
    }
}

const MatomoTracking = () => {
    // action can refer : https://matomo.org/faq/reports/advanced-manually-tracking-ecommerce-actions-in-matomo/
    const MTM_URL = process.env.REACT_APP_MTM_URL;
    const MTM_SITE_ID = process.env.REACT_APP_MTM_SITE_ID;

    useEffect(() => {
        if (!MTM_URL || !MTM_SITE_ID) {
            console.error("Matomo tracking not initialized: MTM_URL or MTM_SITE_ID is undefined");
            return;
        }

        if (process.env.REACT_APP_DEPLOY_ENV == "uat") {
            console.error("Matomo tracking only for production website")
            return;
        }

        var _paq = window._paq = window._paq || [];

        // Enable link tracking and heartbeat timer
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        _paq.push(['enableHeartBeatTimer']);

        const initializeMatomo = () => {
            const u = `${MTM_URL}/`;
            _paq.push(['setTrackerUrl', `${u}js/tracker.php`]);
            _paq.push(['setSiteId', MTM_SITE_ID]);

            const d = document;
            const g = d.createElement('script');
            g.async = true;
            g.src = `${u}js/tracker.php`;
            const s = d.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(g, s);
        };

        initializeMatomo();
    }, []);

    return null;
}

export default MatomoTracking