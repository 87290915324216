// react
import React, { Component } from "react";

// third-party
import PropTypes from "prop-types";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { ScrollContext } from "react-router-scroll-4";

// application
import languages from "../i18n";
import { localeChange } from "../store/locale";
import { getSystemSettings } from "../api/general";

// pages
import Layout from "./Layout";
import SiteVoucherDetails from "./site/SiteVoucherDetails";
import SitePageInvoice from "./site/SitePageInvoice";
import SitePageMaintenance from "./site/SitePageMaintenance";
import MatomoTracking from "../MatomoTracking";

class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: null,
        };
    }

    componentDidMount() {
        var doc = document.documentElement;
        doc.setAttribute('data-useragent', navigator.userAgent);

        // preloader
        setTimeout(() => {
            const preloader = document.querySelector(".site-preloader");

            preloader.addEventListener("transitionend", (event) => {
                if (event.propertyName === "opacity") {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add("site-preloader__fade");
        }, 800);

        getSystemSettings().then((res) => {
            if (res.data) {
                this.setState({
                    settings: res.data,
                });
            }
        });
    }

    shouldUpdateScroll = (prevRouterProps, { location }) => prevRouterProps && location.pathname !== prevRouterProps.location.pathname;

    render() {
        const { locale } = this.props;
        const { settings } = this.state;
        const { messages, direction } = languages[locale];

        return (
            <IntlProvider locale={locale} messages={messages}>
                <MatomoTracking />
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                        <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                            <Switch>
                                <Route exact path="/under-maintenance" component={SitePageMaintenance} />
                                <Route exact path="/voucher-details/:code" component={SiteVoucherDetails} />
                                <Route exact path="/invoice/:orderCode" component={SitePageInvoice} />
                                <Route path="/" render={(props) => <Layout {...props} settings={settings} />} />
                                <Redirect to="/" />
                            </Switch>
                        </ScrollContext>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
